import React from "react"
// import SEO from "../components/seo"
import styled from '@emotion/styled'

const Article = styled(`article`)({
  margin: `0 0 2rem 0`,
  minHeight: `20rem`,
  [`p`]: {
    marginBottom: `0`
  }
})

const Header = styled(`header`)({
  marginBottom: `1rem`
})

const NotFoundPage = ({ data, location }) => {
  return (
    <React.Fragment>
      {/*<SEO title="All events" />*/}
      <Article>
        <section>
          <p>Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span></p>
          <p>We couldn’t find what you were looking for.</p>
        </section>
      </Article>
    </React.Fragment>
  )
}

export default NotFoundPage
